import { royaltyInvoiceConstants } from "../../constants";
import { hideLoader, showLoader } from "./Loader";
import * as FileSaver from 'file-saver';
import { pdtConstants } from '../../constants';
import {
  getRoyaltyPartnerList,
  getRoyaltyInvoiceRequestData,
  deleteInvoiceRequest,
  addInvoiceRequest,
  editInvoiceRequest,
  getSingleRoyaltyInvoiceRequestData,
  downloadinvoiceDocument,
  sendInvoiceReminderApi,
} from "../../services";
import { showMessage } from "../../helpers";

export const getRoyaltyPartnersList = () => {
  return (dispatch) => {
    dispatch({ type: royaltyInvoiceConstants.GET_PARTNERS_LIST_REQUEST });
    getRoyaltyPartnerList()
      .then((response) => {
        dispatch({
          type: royaltyInvoiceConstants.GET_PARTNERS_LIST_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch({ type: royaltyInvoiceConstants.GET_PARTNERS_LIST_FAILURE });
        console.log(error);
      });
  };
};

export const getPartnerInvoiceData = () => {
  return (dispatch) => {
    dispatch({ type: royaltyInvoiceConstants.GET_PARTNER_INVOICE_REQUEST_DATA_REQUEST });
    getRoyaltyInvoiceRequestData()
      .then((response) => {
        dispatch({
          type: royaltyInvoiceConstants.GET_PARTNER_INVOICE_REQUEST_DATA_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch({ type: royaltyInvoiceConstants.GET_PARTNER_INVOICE_REQUEST_DATA_FAILURE });
        console.log(error);
      });
  };
};

export const getSingleInvoiceRequestData = (id) => {
  return (dispatch) => {
    dispatch({ type: royaltyInvoiceConstants.SINGLE_INVOICE_VALUE_REQUEST });
    getSingleRoyaltyInvoiceRequestData(id)
      .then((response) => {
        dispatch({
          type: royaltyInvoiceConstants.SINGLE_INVOICE_VALUE_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch({ type: royaltyInvoiceConstants.SINGLE_INVOICE_VALUE_FAILURE });
        console.log(error);
      });
  };
};

export const deleteInvoiceRequestData = (id, onSuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({ type: royaltyInvoiceConstants.DELETE_PARTNER_INVOICE_REQUEST_DATA_REQUEST });
    deleteInvoiceRequest(id)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({ type: royaltyInvoiceConstants.DELETE_PARTNER_INVOICE_REQUEST_DATA_SUCCESS });
        showMessage("success", "Removed!");
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({ type: royaltyInvoiceConstants.DELETE_PARTNER_INVOICE_REQUEST_DATA_FAILURE });
        console.log(error);
      });
  };
};

export const raisePartnerInvoiceRequest = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({ type: royaltyInvoiceConstants.RAISE_INVOICE_REQUEST_REQUEST });
    addInvoiceRequest(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({ type: royaltyInvoiceConstants.RAISE_INVOICE_REQUEST_SUCCESS });
        showMessage("success", "Invoice Request raised succcessfully!");
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({ type: royaltyInvoiceConstants.RAISE_INVOICE_REQUEST_FAILURE });
        let errorMsg =
          error && error.error
            ? error.error.filter((value) => value !== "None")
            : "";
        if (errorMsg.length) {
          showMessage("error", "Error");
        }
      });
  };
};

export const editRoyaltyInvoiceRequestData = (id, data, onSuccess,status) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({ type: royaltyInvoiceConstants.EDIT_INVOICE_REQUEST_REQUEST });
    editInvoiceRequest(id, data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({ type: royaltyInvoiceConstants.EDIT_INVOICE_REQUEST_SUCCESS });
        if(status){
          showMessage("success", status);
        }else{
          showMessage("success", "Request edited succcessfully!");
        }

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({ type: royaltyInvoiceConstants.EDIT_INVOICE_REQUEST_FAILURE });
        let errorMsg =
          error && error.error
            ? error.error.filter((value) => value !== "None")
            : "";
        if (errorMsg.length) {
          showMessage("error", errorMsg[0]);
          showMessage("error", "Error");
        }
      });
  };
};

export const downloadInvoiceDocument = (filename, requestObj, onSuccess) => {
  return dispatch => {
      dispatch(showLoader());
      dispatch({ type: pdtConstants.REPORT_DOWNLOAD_PDT_REQUEST });
      downloadinvoiceDocument(requestObj).then(response => {
          dispatch(hideLoader());
          dispatch({ type: pdtConstants.REPORT_DOWNLOAD_PDT_SUCCESS });
          FileSaver.saveAs(response, filename);
          if (onSuccess) {
              onSuccess()
          }
      }).catch(
          error => {
              dispatch(hideLoader());
              dispatch({ type: pdtConstants.REPORT_DOWNLOAD_PDT_FAILURE });
              console.log(error)
          }
      )
  }
}

export const sendInvoiceReminder = (tracking_id) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({ type: royaltyInvoiceConstants.SEND_INVOICE_REMINDER_REQUEST });

    sendInvoiceReminderApi(tracking_id)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({ type: royaltyInvoiceConstants.SEND_INVOICE_REMINDER_SUCCESS });
        showMessage("success", "Reminder sent successfully!");
        return response;
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({ type: royaltyInvoiceConstants.SEND_INVOICE_REMINDER_FAILURE });
        showMessage("error", "Failed to send reminder");
        console.error("Error sending reminder:", error);
      });
  };
};

