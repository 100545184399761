import { config } from "../constants";
import { authHeader } from "../helpers";
import { handleResponse } from "./handle-response";

export const addPartner = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${config.API_URL}/admin/partner/`, requestOptions).then(
    handleResponse
  );
};

export const editPartner = (id, data) => {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${config.API_URL}/admin/partner/${id}/`, requestOptions).then(
    handleResponse
  );
};

export const getPartnerList = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(`${config.API_URL}/admin/partner/`, requestOptions).then(
    handleResponse
  );
};

export const getPartner = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(`${config.API_URL}/admin/partner/${id}/`, requestOptions).then(
    handleResponse
  );
};

export const deletePartner = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(`${config.API_URL}/admin/partner/${id}/`, requestOptions).then(
    handleResponse
  );
};

export const sendReportReminder = (partner_id, template_type) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ partner_id, template_type }),
  };

  return fetch(`${config.API_URL}/template/reminder/`, requestOptions).then(
    handleResponse
  );
};

export const getRegionList = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(`${config.API_URL}/country_list/`, requestOptions).then(
    handleResponse
  );
};

export const getPartnerProductList = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/partner-active-product-list/`,
    requestOptions
  ).then(handleResponse);
};
export const getRoyaltyPartnerList = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/royalty-partner-list/`,
    requestOptions
  ).then(handleResponse);
};
export const getLicensorsPartnerList = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/licensors-partner/`,
    requestOptions
  ).then(handleResponse);
};
export const getRoyaltySalesRequestData = (isApproved = false, partner_id=null) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/partner/royalty-sales-request/${isApproved ? `?is_approved=${isApproved}` : ''}${(partner_id && isApproved)? `&partner_id=${partner_id}` : partner_id? `?partner_id=${partner_id}` : ''}`,
    requestOptions
  ).then(handleResponse);
};

export const getRoyaltyInvoiceRequestData = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/partner/royalty-invoice-tracking/`,
    requestOptions
  ).then(handleResponse);
};

export const getRoyaltyTaxInvoiceRequestData = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/partner/royalty-tax-invoice/`,
    requestOptions
  ).then(handleResponse);
};

export const getRoyaltySalesMappedData = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/partner/royalty-sales-mapped-data/?request_id=${id}`,
    requestOptions
  ).then(handleResponse);
};

export const getSingleRoyaltySalesRequestData = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/partner/royalty-sales-request/${id}/`,
    requestOptions
  ).then(handleResponse);
};
export const getSingleRoyaltyInvoiceRequestData = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/partner/royalty-invoice-tracking/${id}/`,
    requestOptions
  ).then(handleResponse);
};

export const getSingleTaxRequest = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/partner/royalty-tax-invoice/${id}/`,
    requestOptions
  ).then(handleResponse);
};

export const addSalesRequest = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: data,
  };

  return fetch(
    `${config.API_URL}/partner/royalty-sales-request/`,
    requestOptions
  ).then(handleResponse);
};
export const addInvoiceRequest = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: data,
  };

  return fetch(
    `${config.API_URL}/partner/royalty-invoice-tracking/`,
    requestOptions
  ).then(handleResponse);
};

export const addTaxInvoiceRequest = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: data,
  };

  return fetch(
    `${config.API_URL}/partner/royalty-tax-invoice/`,
    requestOptions
  ).then(handleResponse);
};

export const editSalesRequest = (id, data) => {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader() },
    body: data,
  };

  return fetch(
    `${config.API_URL}/partner/royalty-sales-request/${id}/`,
    requestOptions
  ).then(handleResponse);
};
export const editInvoiceRequest = (id, data) => {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader() },
    body: data,
  };

  return fetch(
    `${config.API_URL}/partner/royalty-invoice-tracking/${id}/`,
    requestOptions
  ).then(handleResponse);
};
export const editTaxInvoiceRequestData = (id, data) => {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader() },
    body: data,
  };

  return fetch(
    `${config.API_URL}/partner/royalty-tax-invoice/${id}/`,
    requestOptions
  ).then(handleResponse);
};

export const deleteSalesRequest = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/partner/royalty-sales-request/${id}/`,
    requestOptions
  ).then(handleResponse);
};
export const deleteInvoiceRequest = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/partner/royalty-invoice-tracking/${id}/`,
    requestOptions
  ).then(handleResponse);
};

export const setReportCutOffDate = ({ date }) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ date }),
  };

  return fetch(`${config.API_URL}/admin/cut_off_date/`, requestOptions).then(
    handleResponse
  );
};

export const getReportCutOffDate = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(`${config.API_URL}/admin/cut_off_date/`, requestOptions).then(
    handleResponse
  );
};

export const setLetterCutOffDate = ({ date }) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ date }),
  };

  return fetch(
    `${config.API_URL}/admin/letter_submission_date/`,
    requestOptions
  ).then(handleResponse);
};

export const getLetterCutOffDate = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/admin/letter_submission_date/`,
    requestOptions
  ).then(handleResponse);
};

export const downloadinvoiceDocument = (requestObj) => {
  const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(requestObj),
      responseType: 'blob'
  };

  return fetch(`${config.API_URL}/invoice/royalty/download/`, requestOptions)
      .then(response => response.blob())

}

export const downloadRoyaltyPOApprovalRequestsDocument = (requestObj) => {
  const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(requestObj),
      responseType: 'blob'
  };

  return fetch(`${config.API_URL}/po_approval/royalty/download/`, requestOptions)
      .then(response => response.blob())

}

export const sendInvoiceReminderApi = (tracking_id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ tracking_id }),
  };

  return fetch(`${config.API_URL}/invoice-approval-reminder/`, requestOptions)
    .then(handleResponse);
};
