export const royaltyInvoiceConstants = {
    GET_PARTNERS_LIST_REQUEST: 'GET_PARTNERS_LIST_REQUEST',
    GET_PARTNERS_LIST_SUCCESS: 'GET_PARTNERS_LIST_SUCCESS',
    GET_PARTNERS_LIST_FAILURE: 'GET_PARTNERS_LIST_FAILURE',

    GET_PARTNER_INVOICE_REQUEST_DATA_REQUEST: 'GET_PARTNER_INVOICE_REQUEST_DATA_REQUEST',
    GET_PARTNER_INVOICE_REQUEST_DATA_SUCCESS: 'GET_PARTNER_INVOICE_REQUEST_DATA_SUCCESS',
    GET_PARTNER_INVOICE_REQUEST_DATA_FAILURE: 'GET_PARTNER_INVOICE_REQUEST_DATA_FAILURE',

    DELETE_PARTNER_INVOICE_REQUEST_DATA_REQUEST: 'DELETE_PARTNER_INVOICE_REQUEST_DATA_REQUEST',
    DELETE_PARTNER_INVOICE_REQUEST_DATA_SUCCESS: 'DELETE_PARTNER_INVOICE_REQUEST_DATA_SUCCESS',
    DELETE_PARTNER_INVOICE_REQUEST_DATA_FAILURE: 'DELETE_PARTNER_INVOICE_REQUEST_DATA_FAILURE',

    RAISE_INVOICE_REQUEST_REQUEST: 'RAISE_INVOICE_REQUEST_REQUEST',
    RAISE_INVOICE_REQUEST_SUCCESS: 'RAISE_INVOICE_REQUEST_SUCCESS',
    RAISE_INVOICE_REQUEST_FAILURE: 'RAISE_INVOICE_REQUEST_FAILURE',

    EDIT_INVOICE_REQUEST_REQUEST: 'EDIT_INVOICE_REQUEST_REQUEST',
    EDIT_INVOICE_REQUEST_SUCCESS: 'EDIT_INVOICE_REQUEST_SUCCESS',
    EDIT_INVOICE_REQUEST_FAILURE: 'EDIT_INVOICE_REQUEST_FAILURE',

    SINGLE_INVOICE_VALUE_REQUEST: 'SINGLE_INVOICE_VALUE_REQUEST',
    SINGLE_INVOICE_VALUE_SUCCESS: 'SINGLE_INVOICE_VALUE_SUCCESS',
    SINGLE_INVOICE_VALUE_FAILURE: 'SINGLE_INVOICE_VALUE_FAILURE',

    SEND_INVOICE_REMINDER_REQUEST: 'SEND_INVOICE_REMINDER_REQUEST',
    SEND_INVOICE_REMINDER_SUCCESS: 'SEND_INVOICE_REMINDER_SUCCESS',
    SEND_INVOICE_REMINDER_FAILURE: 'SEND_INVOICE_REMINDER_FAILURE',
};